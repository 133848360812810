import React, { useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  Login,
  Dashboard,
  NoticeListPage,
  ReviewListPage,
  NoticeDetailPage,
  Order,
  Event,
  EventRegister,
  Business,
  LogData,
  PersonalProduct,
  Brand,
  Settings,
  As,
  AsRegister,
  Member,
  CouponRegister,
  PersonalOrder,
  Product,
  ProductRegister,
  OpenMarketRegister,
  OpenMarketEdit,
  Check,
} from "../pages";
import { SWRConfig } from "swr";
import AlertPopup from "../component/popup/AlertPopup";
import swrConfig from "../swrConfig";
import useLoad from "../hooks/useLoad";
import WithAccessAuth from "../hoc/WithAccessAuth";
import Core from "../container/base/Core";
import auth from "../utils/auth";

const App = () => {
  const [alertPopup, setAlertPopup] = useState(null);

  const failAlertPopup = () => {
    // localStorage.clear();
    setAlertPopup({
      state: "fail",
      title: "접근불가",
      text: "접근 권한에 따라 접근이 불가합니다.",
    });
  };

  useLoad();

  const interval = useRef(null);
  const [logoutTime, setLogoutTime] = useState(null);

  function setDateTime(item,type,unit){
    let setDate = "";
    if(item){
      let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
      let date = new Date(basicDate),
        yy,
        mm,
        dd,
        th,
        tm,
        ts;
  
      yy = date.getFullYear();
      mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
      th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
      tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
      ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();
      
      if(type == "yymmdd"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일";
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd;
        }else if(unit == "not"){
          setDate = `${yy}${mm}${dd}`;
        }else{
          setDate = yy + "." + mm + "." + dd;
        }
      }else if(type == "yymmddhhtt"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
        }else{
          setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
        }
      }else if(type == "mmdd"){
        if(unit == "ymd"){
          setDate = mm + "월 " + dd + "일";
        }else if(unit == "hymd"){
          setDate = mm + "-" + dd;
        }else if(unit == "slash"){
          setDate = mm + "/" + dd;
        }else{
          setDate = mm + "." + dd;
        }
      }else if(type == "yymm"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월";
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm;
        }else if(unit == "slash"){
          setDate = yy + "/" + mm;
        }else{
          setDate = yy + "." + mm;
        }
      }else if(type == "day"){
        setDate = dd;
      }else if(type == "yymmddhhttss"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
        }else{
          setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
        }
      }else if(type == "hhttss"){
        setDate = th + ":" + tm + ":" + ts;
      }else if(type == "hhtt"){
        setDate = th + ":" + tm;
      }else{
        setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
      }
    }
    return setDate;
  }

  function logoutTimeSetting(){
    fetch(`${process.env.REACT_APP_API_END_POINT}/manager/setting/logout`, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
          'Accept' : 'application/json'
      },
    }).then(function(response) {
        return response.json();
    }).then(function(fetchRd) {
        if (fetchRd.success == true) {
            setLogoutTime(fetchRd?.data?.logout_time);

            if (fetchRd?.data?.logout_time > 0) {
              let nowTime = new Date();
              nowTime.setMinutes(nowTime.getMinutes() + Number(fetchRd?.data?.logout_time));
              localStorage.setItem("logoutTime", setDateTime(nowTime,"yymmddhhttss","hymd"));
            }
        }
    });
  }
  
  useEffect(() => {
    let nowTime = new Date();
    if(logoutTime || logoutTime === 0){
      nowTime.setMinutes(nowTime.getMinutes() + Number(logoutTime));
      localStorage.setItem("logoutTime", setDateTime(nowTime,"yymmddhhttss","hymd"));
    }
  }, [logoutTime]);

  useEffect(() => {
    window.addEventListener('click', logoutTimeSetting);

    return () => {
      window.removeEventListener('click', logoutTimeSetting);
    };
  }, []);

  useEffect(() => {
    if(localStorage.getItem("token") && !localStorage.getItem("logoutTime")){
      logoutTimeSetting();
    }else if(localStorage.getItem("logoutTime")){
      interval.current = setInterval(function() {

        let chkTime = new Date();
          let logoutTimeSet = String(localStorage.getItem("logoutTime")).indexOf("-") > 0 || String(localStorage.getItem("logoutTime")).indexOf(".") > 0 ? String(localStorage.getItem("logoutTime")).replace(/-\./g,"/") : localStorage.getItem("logoutTime");
          let logoutTime = new Date(logoutTimeSet);

          if(chkTime >= logoutTime){
            clearInterval(interval.current);
            localStorage.removeItem("logoutTime");

            auth.clearAppStorage();

            localStorage.setItem("logoutAll",true);

            window.location.href = "/login";
            window.location.reload(true);
          }
      }, 1000);
    }

    return () => {
        clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if(!localStorage.getItem("token") && localStorage.getItem("logoutAll")){
        localStorage.removeItem("logoutAll");
        window.location.href = "/login";
        window.location.reload(true);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <SWRConfig value={swrConfig(failAlertPopup)}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/check" component={Check} />

          {/* 대시보드 */}
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/notice" component={NoticeListPage} />
          <Route
            exact
            path="/dashboard/notice/detail/:detailId"
            component={NoticeDetailPage}
          />
          <Route exact path="/dashboard/review" component={ReviewListPage} />

          {/* 브랜드관리 */}
          <Route exact path="/brand">
            <WithAccessAuth Component={Brand} redirectUrl="/" level={3} />
          </Route>

          {/* 주문관리 */}
          <Route exact path="/order" component={Order} />

          {/* 주문관리 */}
          <Route
            exact
            path="/openmarket/register"
            component={OpenMarketRegister}
          />
          <Route
            exact
            path="/openmarket/edit/:detailId"
            component={OpenMarketEdit}
          />

          {/* 상품관리 */}
          <Route exact path="/product" component={Product} />
          <Route
            exact
            path="/product/detail/:type/:detailId"
            component={ProductRegister}
          />

          {/* 개인결제관리 */}
          <Route exact path="/personal_order">
            <WithAccessAuth
              Component={PersonalOrder}
              redirectUrl="/"
              level={2}
            />
          </Route>

          {/* 회원관리 */}
          <Route exact path="/member" component={Member} />

          {/* 쿠폰등록/수정 */}
          <Route
            exact
            path="/member/edit/coupon/:couponId"
            component={CouponRegister}
          />
          <Route
            exact
            path="/member/register/coupon"
            component={CouponRegister}
          />

          {/* 공지사항/이벤트 */}
          <Route exact path="/event" component={Event} />

          {/* 공지/이벤트 등록 */}
          <Route
            exact
            path="/event/register/:pageType"
            component={EventRegister}
          />
          <Route
            exact
            path="/event/edit/:pageType/:detailId"
            component={EventRegister}
          />

          {/* A/s 일정 */}
          <Route exact path="/as" component={As} />
          <Route exact path="/as/register" component={AsRegister} />
          <Route exact path="/as/edit/:detailId" component={AsRegister} />

          {/* 사업자정보 */}
          <Route exact path="/business" component={Business}>
            <WithAccessAuth Component={Business} redirectUrl="/" level={4} />
          </Route>

          {/* 관리자활동기록 */}
          <Route exact path="/logdata" component={LogData} />

          {/* 관리자활동기록 */}
          <Route exact path="/personal_product" component={PersonalProduct} />

          {/* 설정 */}
          <Route exact path="/settings" component={Settings}>
            <WithAccessAuth Component={Settings} redirectUrl="/" level={4} />
          </Route>
        </Switch>
      </BrowserRouter>
      {alertPopup !== null && (
        <AlertPopup
          handleClose={() => (window.location.href = "/dashboard")}
          title={alertPopup.title}
          text={alertPopup.text}
        />
      )}
      <Core />
    </SWRConfig>
  );
};

export default App;
