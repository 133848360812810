import { useSetRecoilState } from "recoil";
import { alertModalState } from "../recoil/atom/modal";

function useAlert() {
  const setAlertModal = useSetRecoilState(alertModalState);

  const accessAuthAlert = (onConfirm) => {
    setAlertModal({
      title: "접근불가",
      text: "접근 권한에 따라 접근이 불가합니다.",
      onConfirm,
    });
  };
  const editAuthAlert = (onConfirm) => {
    setAlertModal({
      title: "수정불가",
      text: "권한에 따라 수정이 불가합니다.",
      onConfirm,
    });
  };

  return { accessAuthAlert, editAuthAlert };
}

export default useAlert;
