export function getPaymentMethod(paymentMethod, tossPaymentMethod) {
  if (paymentMethod === "card") {
    return "카드결제";
  } else if (paymentMethod === "vbank") {
    return "무통장입금";
  } else if (paymentMethod === "bank") {
    return "계좌이체";
  } else if (paymentMethod === "cash") {
    return "현금결제";
  } else if (paymentMethod === "kakao") {
    return "카카오페이";
  } else if (paymentMethod === "naver") {
    return "네이버페이";
  } else if (paymentMethod === "toss") {
    if (tossPaymentMethod) {
      return tossPaymentMethod
    } else {
      return "토스 결제"
    }
  } else {
    return "-";
  }
}

export function getObjectSelectboxList(selectList) {
  return selectList.reduce((prev, curr) => {
    prev[curr.value] = curr.text;
    return prev;
  }, {});
}

export function getSelectListValue(selectList, value) {
  return selectList.find((select) => select.value === value);
}

export function getPercentToString(origin, discounted) {
  if (!origin || !discounted) return "0%";
  const x = Math.ceil((discounted / origin) * 100);
  return `${100 - x}%`;
}

export function getPercent(origin, discounted) {
  if (!origin || !discounted) return 0;
  const x = Math.ceil((discounted / origin) * 100);
  return 100 - x;
}
export function getThousandCommaPrice(price) {
  if (price === 0 || !price) return "0";
  return price.toString().replace((/\B(?=(\d{3})+(?!\d))/g, ","));
}

export function getCommission(price, eventPrice) {
  if (eventPrice === 0) return 0;
  const commision = (1 - Number(price) / Number(eventPrice)) * 100;

  const result = Math.floor(commision.toFixed(2) * 10) / 10;

  return result > 99.9 ? 99.9 : result;
}

export function getOriginPriceByCommision(price, commision) {
  return Math.ceil(price / (1 - commision / 100));
}

export function getInputValueOfNumberType(value) {
  let resultValue = value;
  if (value !== "0" && value[0] === "0") {
    resultValue = value.slice(1);
  }

  if (value === "") return 0;
  return Number(resultValue.replace(/[^0-9]/g, ""));
}

export const getCorrectBranchName = (branch) => {
  if (branch === "all") return "전체";
  if (branch === "부천점") return "부천점";
  if (branch === "광교점") return "광교점";
  if (branch === "인천점") return "인천점";
  if (branch === "반포점") return "반포점";
  return branch;
};
