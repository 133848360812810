import { api } from "./utils/api";

function swrConfig(alertPopup) {
  return {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        if (!res?.data?.success) {
          alertPopup();
        } else {
          return res.data?.data || res.data;
        }
      });
    },
  };
}

export default swrConfig;
