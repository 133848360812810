import styled from "@emotion/styled";
import React, { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";

function ModalWrapper({ children, onClose }) {
  useEffect(() => {
    document.body.style.cssText = `overflow: hidden`;
    return () => {
      document.body.style.cssText = `overflow: "auto"`;
    };
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Wrapper>{children}</Wrapper>;
    </OutsideClickHandler>
  );
}

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;

  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ModalWrapper;
