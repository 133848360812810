import React, { useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../Button";

//알림창이기 때문에 firstbtnlabel 없으면 기본 "확인" 버튼 나오도록 설정
const AlertPopup = ({
  display,
  title,
  text,
  firstBtnStyle,
  firstBtnLabel,
  firstBtnClick,
  secondBtnStyle,
  secondBtnLabel,
  secondBtnClick,
  thirdBtnLabel,
  thirdBtnClick,
  handleClose,
  popupStyle = "",
  firstBtnDisabled = false,
  children,
}) => {
  useEffect(() => {
    document.body.style.cssText = `overflow: hidden`;

    return () => {
      document.body.style.cssText = `overflow: "auto"`;
    };
  }, []);

  return (
    <PopupArea displayType={display}>
      <PopupWra>
        {title && <PopupTitle>{title}</PopupTitle>}
        {text && (
          <PopupDesc dangerouslySetInnerHTML={{ __html: text }}></PopupDesc>
        )}
        {children}
        {(handleClose || firstBtnClick) && (
          <ButtonArea>
            {thirdBtnLabel && (
              <Button
                type="button"
                className="transparent auto"
                onClick={thirdBtnClick}
              >
                {thirdBtnLabel}
              </Button>
            )}
            {secondBtnLabel && (
              <Button
                type="button"
                className={
                  secondBtnStyle ? `${secondBtnStyle} auto` : "transparent auto"
                }
                onClick={secondBtnClick}
              >
                {secondBtnLabel}
              </Button>
            )}
            <Button
              type="button"
              className={firstBtnStyle ? `${firstBtnStyle} auto` : "auto"}
              onClick={firstBtnClick ? firstBtnClick : handleClose}
              disabled={firstBtnDisabled}
            >
              {firstBtnLabel ? firstBtnLabel : "확인"}
            </Button>
          </ButtonArea>
        )}
      </PopupWra>
      <PopupBg
        className={popupStyle}
        onClick={
          handleClose
            ? handleClose
            : secondBtnClick
            ? secondBtnClick
            : firstBtnClick
        }
      />
    </PopupArea>
  );
};

const ButtonArea = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: end;

  & button:nth-of-type(2) {
    margin-left: 10px;
  }
`;
const PopupDesc = styled.div`
  color: #555;

  @media (max-width: 1023px) {
    font-size: 12px;
  }
`;
const PopupTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;

  @media (max-width: 1023px) {
    font-size: 16px;
  }
`;
const PopupWra = styled.div`
  border-radius: 12px;
  background: #fff;
  position: absolute;
  width: 560px;
  padding: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media (max-width: 1023px) {
    width: 360px;
  }
`;

const PopupBg = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(136, 136, 136, 0.4);
`;
const PopupArea = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1500;
`;

export default AlertPopup;
