import Axios from "axios";
import auth from "./auth";
export const API_END_POINT = process.env.REACT_APP_API_END_POINT;

export const api = Axios.create({
  baseURL: API_END_POINT,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      try {
        if (auth.getRefreshToken() !== "undefined") {
          const {
            data: {
              data: { access_token, refresh_token },
            },
          } = await api.post("/refresh/token", {
            refresh_token: auth.getRefreshToken(),
          });
          auth.setToken(access_token);
          auth.setRefreshToken(refresh_token);
          api.defaults.headers.common["Authorization"] = access_token;
          return api.request(error.config);
        } else {
          throw new Error();
        }
      } catch (err) {
        auth.clear("token");
        auth.clear("refresh_token");
        return null;
      }
    }
    return Promise.reject(error);
  }
);
